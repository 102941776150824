( function($) {
	
	$(window).load(function () { //window load pour Safari & Chrome, car Document Ready ne prend pas en compte le chargement des IMG
		
		console.log('test');
		
		//$('.fotorama').fotorama();
		var $fotoramaDiv = $('.fotorama').fotorama();
		var fotorama = $fotoramaDiv.data('fotorama');
		
		/*fotorama.resize({
		  width: 667,
		  height: 500,
		});*/
		
		$('.owl-carousel').owlCarousel({
		    loop:true,
		    autoWidth:true,
		    autoplay:true,
		    autoplayTimeout:3000,
		    smartSpeed:1500,
		    //navSpeed: 1250,
		    autoplayHoverPause:false,
		    nav:false,
		    margin: 50,
		    
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:3
		        },
		        1000:{
		            items:5
		        }
		    }
		});
		
		setCarouselHeight ();
		stickyFooter();
		
	});

	$(document).ready(function(){
		
		//setCarouselHeight ();
		setMenuCenter();
				
		$('.previous-post a, .next-post a').hover(
		
			function() {
				$(this).find("img").addClass("hovered");
				$(this).find("i").addClass("hovered");
			},
			
			function() {
				$(this).find("img").removeClass("hovered");
				$(this).find("i").removeClass("hovered");
			}
		
		);
		
		$('.box-oeuvre').hover(
		
			function() {
				$(this).find("img").addClass("hovered");
				$(this).find(".titre").addClass("hovered");
				$(this).addClass("hovered");
			},
			
			function() {
				$(this).find("img").removeClass("hovered");
				$(this).find(".titre").removeClass("hovered");
				$(this).removeClass("hovered");
			}
		
		);
		
		//stickyFooter();
		
	
	}); //fin document ready
	
	$(window).resize(function () {
		setCarouselHeight ();
		setMenuCenter();
	});
	
	function stickyFooter () {
		var topFooter = $("footer").offset().top;
		var hauteurFooter = $("footer").height();
		//console.log(topFooter);
		//console.log(hauteurFooter);
		//console.log($(document).height());
		//console.log($(window).height());
		
		if($(document).height()>(topFooter+hauteurFooter+10)){
			$("footer").addClass("sticky");
		}
	}
	
	function setCarouselHeight () {
		if($(window).width()>768) {
			var hauteurMenu = $(".container-menu").height();
			var hauteurLogo = $(".container-logo").outerHeight();
			var hauteurFenetre = $(window).height();
			if( $("footer").css("position")==="fixed") {
				var hauteurFooter = $("footer").height();
			}
				
			else {
				var hauteurFooter = 0;
			}
				
				
			var hauteurDispo = hauteurFenetre - hauteurMenu - hauteurLogo - hauteurFooter -471 ;
			var paddingTopCarousel = hauteurDispo / 2;
			$("#front-carousel").css("paddingTop",paddingTopCarousel+'px');
		}
	}
	
	function setMenuCenter () {
		if($(window).width()>768) {
			var largeurContainerMenu = $(".container-menu nav").width();
			var largeurMenu = $("#menu-menu-1").width();
			var marginMenu = (largeurContainerMenu - largeurMenu ) /2;
			$("#menu-menu-1").css("marginLeft",marginMenu);
		}
		else {
			$("#menu-menu-1").css("marginLeft",0);
		}
	}

} ) ( jQuery );